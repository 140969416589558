import { Emitter, EventHandlerMap } from 'mitt';

import { SessionState } from './session-state';
import { SessionOptions } from './session-options';
import { SessionErrorCode } from './session-errors';

export enum SessionContext {
  PRELOAD = 'PRELOAD',
  LOADED = 'LOADED',
}

export enum SessionEvent {
  STATE_CHANGED = '@SESSION_BUS/STATE_CHANGED',
  SESSION_OPTIONS_CHANGED = '@SESSION_BUS/SESSION_OPTIONS_CHANGED',

  SESSION_INSTALLATION_FAILED = '@SESSION_BUS/SESSION_INSTALLATION_FAILED',
  SESSION_INSTALLATION_STARTED = '@SESSION_BUS/SESSION_INSTALLATION_STARTED',
  SESSION_INSTALLATION_FINISHED = '@SESSION_BUS/SESSION_INSTALLATION_FINISHED',

  SESSION_WIDGET_FETCHING_FAILED = '@SESSION_BUS/SESSION_WIDGET_FETCHING_FAILED',
  SESSION_WIDGET_FETCHING_STARTED = '@SESSION_BUS/SESSION_WIDGET_FETCHING_STARTED',
  SESSION_WIDGET_FETCHING_FINISHED = '@SESSION_BUS/SESSION_WIDGET_FETCHING_FINISHED',

  SESSION_WIDGET_INITIALIZATION_FAILED = '@SESSION_BUS/SESSION_WIDGET_INITIALIZATION_FAILED',
  SESSION_WIDGET_INITIALIZATION_STARTED = '@SESSION_BUS/SESSION_WIDGET_INITIALIZATION_STARTED',
  SESSION_WIDGET_INITIALIZATION_FINISHED = '@SESSION_BUS/SESSION_WIDGET_INITIALIZATION_FINISHED',
}

export type SessionEvents = {
  [SessionEvent.STATE_CHANGED]: SessionState;
  [SessionEvent.SESSION_OPTIONS_CHANGED]: SessionOptions;

  [SessionEvent.SESSION_INSTALLATION_FAILED]: SessionErrorCode;
  [SessionEvent.SESSION_INSTALLATION_STARTED]: SessionOptions;
  [SessionEvent.SESSION_INSTALLATION_FINISHED]: undefined;

  [SessionEvent.SESSION_WIDGET_FETCHING_STARTED]: undefined;
  [SessionEvent.SESSION_WIDGET_FETCHING_FINISHED]: undefined;

  [SessionEvent.SESSION_WIDGET_INITIALIZATION_STARTED]: undefined;
  [SessionEvent.SESSION_WIDGET_INITIALIZATION_FINISHED]: undefined;
};

export type SessionEventEmitter = Emitter<SessionEvents>;
export type SessionEventsHandlerMap = EventHandlerMap<SessionEvents>;
