export interface SessionOptionsBase {
  offer_id?: string | number;
  campaign_id?: string | number;
  aff_id?: string | number;
  outlinks_set_id?: string | number;
  override_gateway?: string;
  override_url?: string;
  debug?: boolean;
  fallback_outlinks?: Record<string, string>;
  popunders?: boolean;
  visitorIdGeneration?: boolean;
  url?: string;
}

export interface SessionOptions extends SessionOptionsBase {
  url_id?: string | number;
}

export interface SessionInitOptions extends SessionOptionsBase {
  url_id?: Record<string, string>;
}

export function createDefaultSessionOptions(p: Partial<SessionOptions> = {}): SessionOptions {
  return {
    offer_id: '',
    campaign_id: '',
    url_id: '',
    aff_id: '',
    outlinks_set_id: '',
    override_gateway: '',
    override_url: '',
    debug: false,
    fallback_outlinks: {},
    popunders: true,
    visitorIdGeneration: true,

    ...p,
  };
}
