import { SessionErrorCode } from './session-errors';
import { SessionOptions, createDefaultSessionOptions } from './session-options';

export enum SessionRunnerStatus {
  IDLE = 'idle',
  DONE = 'done',
  RUNNING = 'running',
}

export type SessionState =
  | {
      error: undefined;
      options: SessionOptions;
      fetchingStatus: SessionRunnerStatus.RUNNING;
      initializationStatus: SessionRunnerStatus.IDLE;
    }
  | {
      error: SessionErrorCode;
      options: SessionOptions;
      fetchingStatus: SessionRunnerStatus.IDLE;
      initializationStatus: SessionRunnerStatus.IDLE;
    }
  | {
      error: undefined;
      options: SessionOptions;
      fetchingStatus: 'done';
      initializationStatus: SessionRunnerStatus.IDLE;
    }
  | {
      error: undefined;
      options: SessionOptions;
      fetchingStatus: SessionRunnerStatus.DONE;
      initializationStatus: SessionRunnerStatus.RUNNING;
    }
  | {
      error: SessionErrorCode;
      options: SessionOptions;
      fetchingStatus: SessionRunnerStatus.DONE;
      initializationStatus: SessionRunnerStatus.IDLE;
    }
  | {
      error: undefined;
      options: SessionOptions;
      fetchingStatus: SessionRunnerStatus.DONE;
      initializationStatus: SessionRunnerStatus.DONE;
    };

export function selectIsLoading(state: SessionState): boolean {
  return [state.fetchingStatus, state.initializationStatus].some((status) => status === SessionRunnerStatus.RUNNING);
}

export function selectErrorCode(state: SessionState): SessionErrorCode | undefined {
  return state.error;
}

export function selectIsReady(state: SessionState): boolean {
  return [state.fetchingStatus, state.initializationStatus].every((status) => status === SessionRunnerStatus.DONE);
}

export function selectIsInitializedAndFetched(state: SessionState): boolean {
  const error = selectErrorCode(state);
  const isReady = selectIsReady(state);
  const isLoading = selectIsLoading(state);

  return !error && !isLoading && isReady;
}

export enum SessionActionType {
  SESSION_INSTALLATION_FAILED = '@SESSION/SESSION_INSTALLATION_FAILED',
  SESSION_INSTALLATION_STARTED = '@SESSION/SESSION_INSTALLATION_STARTED',

  SESSION_WIDGET_FETCHING_STARTED = '@SESSION/SESSION_WIDGET_FETCHING_STARTED',
  SESSION_WIDGET_FETCHING_FINISHED = '@SESSION/SESSION_WIDGET_FETCHING_FINISHED',

  SESSION_WIDGET_INITIALIZATION_STARTED = '@SESSION/SESSION_WIDGET_INITIALIZATION_STARTED',
  SESSION_WIDGET_INITIALIZATION_FINISHED = '@SESSION/SESSION_WIDGET_INITIALIZATION_FINISHED',
}

export interface SessionInstallationFailedAction {
  type: SessionActionType.SESSION_INSTALLATION_FAILED;
  error: SessionErrorCode;
}

export interface SessionInstallationStartedAction {
  type: SessionActionType.SESSION_INSTALLATION_STARTED;
  options: SessionOptions;
}

export interface SessionWidgetFetchingStartedAction {
  type: SessionActionType.SESSION_WIDGET_FETCHING_STARTED;
}

export interface SessionWidgetFetchingSucceededAction {
  type: SessionActionType.SESSION_WIDGET_FETCHING_FINISHED;
}

export interface SessionWidgetInitializationStartedAction {
  type: SessionActionType.SESSION_WIDGET_INITIALIZATION_STARTED;
}

export interface SessionWidgetInitializationSucceededAction {
  type: SessionActionType.SESSION_WIDGET_INITIALIZATION_FINISHED;
}

export type SessionAction =
  | SessionInstallationFailedAction
  | SessionInstallationStartedAction
  | SessionWidgetFetchingStartedAction
  | SessionWidgetFetchingSucceededAction
  | SessionWidgetInitializationStartedAction
  | SessionWidgetInitializationSucceededAction;

export function sessionStateReducer(state: SessionState, action: SessionAction): SessionState {
  switch (action.type) {
    case SessionActionType.SESSION_INSTALLATION_STARTED: {
      return {
        ...state,
        options: { ...state.options, ...(action?.options || {}) },
      };
    }

    case SessionActionType.SESSION_WIDGET_FETCHING_STARTED: {
      return {
        ...state,
        error: undefined,
        fetchingStatus: SessionRunnerStatus.RUNNING,
        initializationStatus: SessionRunnerStatus.IDLE,
      };
    }

    case SessionActionType.SESSION_WIDGET_FETCHING_FINISHED: {
      return {
        ...state,
        fetchingStatus: SessionRunnerStatus.DONE,
      };
    }

    case SessionActionType.SESSION_WIDGET_INITIALIZATION_STARTED: {
      return {
        ...state,
        error: undefined,
        fetchingStatus: SessionRunnerStatus.DONE,
        initializationStatus: SessionRunnerStatus.RUNNING,
      };
    }

    case SessionActionType.SESSION_WIDGET_INITIALIZATION_FINISHED: {
      return {
        ...state,
        error: undefined,
        fetchingStatus: SessionRunnerStatus.DONE,
        initializationStatus: SessionRunnerStatus.DONE,
      };
    }

    case SessionActionType.SESSION_INSTALLATION_FAILED: {
      return {
        ...state,
        error: action.error,
        fetchingStatus: SessionRunnerStatus.IDLE,
        initializationStatus: SessionRunnerStatus.IDLE,
      };
    }

    default:
      return state;
  }
}

export function createDefaultSessionState(): SessionState {
  const options = createDefaultSessionOptions();
  return {
    error: undefined,
    options,
    fetchingStatus: SessionRunnerStatus.RUNNING,
    initializationStatus: SessionRunnerStatus.IDLE,
  };
}
