import { errorMapper } from './session-errors';
import { SessionWidget } from './session-widget';
import { SessionEvent, SessionEventEmitter } from './session-event-bus';

export class Client {
  constructor(private readonly channel: SessionEventEmitter) {}

  async install(sessionWidget: SessionWidget): Promise<void> {
    try {
      this.channel.emit(SessionEvent.SESSION_INSTALLATION_STARTED, { ...sessionWidget.options });

      this.channel.emit(SessionEvent.SESSION_WIDGET_FETCHING_STARTED);
      await sessionWidget.fetch();
      this.channel.emit(SessionEvent.SESSION_WIDGET_FETCHING_FINISHED);

      this.channel.emit(SessionEvent.SESSION_WIDGET_INITIALIZATION_STARTED);
      await sessionWidget.initialize();
      this.channel.emit(SessionEvent.SESSION_WIDGET_INITIALIZATION_FINISHED);

      this.channel.emit(SessionEvent.SESSION_INSTALLATION_FINISHED);
      return;
    } catch (err) {
      const error = errorMapper(err);
      this.channel.emit(SessionEvent.SESSION_INSTALLATION_FAILED, error);
    } finally {
      await sessionWidget.cleanup();
    }
  }
}
