import Cookies from 'js-cookie';

import { Session, SessionOptions, BrowserSessionWidget, SessionContext } from '../core';

function getLanguageFromCookie() {
  const language = Cookies.get('user-language');

  return language || 'en';
}

function getSettingsByLangCode(language: string): SessionOptions {
  const setting = window.sessionInitInfo;
  const sessionOptions = setting as SessionOptions;
  sessionOptions.url_id = setting.url_id ? getUrlIdByLanguage(setting.url_id, language) : '';
  return sessionOptions;
}

function getUrlIdByLanguage(url_id: Record<string, string>, lang: string): string | undefined {
  return url_id[lang] || url_id['en'];
}

// Avoid double initialization
if (!(window.session && window.session.context === SessionContext.LOADED)) {
  const session = new Session(window?.sessionDataLayer, window?.session?.channel);
  const options = getSettingsByLangCode(getLanguageFromCookie());
  window.session = session;
  window.session.initialize(new BrowserSessionWidget(options));
}
