export enum SessionErrorCode {
  CB = 'cbUnsuccessful',
  REJECTED = 'scriptRejected',
  UNKNOWN_ERROR = 'unknownError',
  WIDGET_MISSING = 'widgetMissing',
  WIDGET_OUTLINK_GETTER_MISSING = 'widgetOutlinkGetterMissing',
}

export class SessionWidgetFetchingError extends Error {
  constructor(err?: string) {
    super(err || 'Error while fetching session widget');
    Object.setPrototypeOf(this, SessionWidgetFetchingError.prototype);
  }
}

export class SessionWidgetMissingError extends Error {
  constructor(err?: string) {
    super(err || 'Session widget missing');
    Object.setPrototypeOf(this, SessionWidgetMissingError.prototype);
  }
}

export class SessionWidgetInitializationError extends Error {
  constructor() {
    super('Session widget initialization failed');
    Object.setPrototypeOf(this, SessionWidgetInitializationError.prototype);
  }
}

export function errorMapper(error: Error | unknown, debug?: boolean | undefined): SessionErrorCode {
  if (debug) {
    console.error(error);
  }

  switch (true) {
    case error instanceof SessionWidgetFetchingError:
      return SessionErrorCode.REJECTED;
    case error instanceof SessionWidgetMissingError:
      return SessionErrorCode.WIDGET_MISSING;
    case error instanceof SessionWidgetInitializationError:
      return SessionErrorCode.CB;
    default:
      return SessionErrorCode.UNKNOWN_ERROR;
  }
}
