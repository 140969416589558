import mitt, { Emitter } from 'mitt';

import { Client } from './session-client';
import { SessionWidget } from './session-widget';
import { SessionOptions } from './session-options';
import { SessionErrorCode } from './session-errors';
import { SessionContext, SessionEvent, SessionEventEmitter, SessionEvents } from './session-event-bus';
import { createDefaultSessionState, sessionStateReducer, SessionAction, SessionState, SessionActionType } from './session-state';

export interface SessionDestroyer {
  destroy(): void;
}

export interface SessionInitializer {
  initialize(sessionWidget: SessionWidget): Promise<void>;
  channel: SessionEventEmitter;
  context: SessionContext;
}

export type SessionStateChangeHandler = (state: SessionState) => void;

export class Session implements SessionDestroyer, SessionInitializer {
  private state: SessionState;

  private readonly client: Client;
  public readonly channel: SessionEventEmitter;
  public readonly context: SessionContext;
  private readonly handlers: Map<string | symbol, () => void> = new Map(); // TODO: WeakMap(Set) at some point?

  private closeEventListeners?: () => void;

  constructor(handlers: SessionStateChangeHandler[], channel?: Emitter<SessionEvents>) {
    this.channel = channel || mitt();
    this.context = SessionContext.LOADED;

    this.state = createDefaultSessionState();
    this.client = new Client(this.channel);
    this.closeEventListeners = this.setupSessionEventListeners();

    this.setupProxyHandlers(handlers);

    this.destroy = this.destroy.bind(this);
    this.dispatch = this.dispatch.bind(this);
    this.initialize = this.initialize.bind(this);
  }

  async initialize(sessionWidget: SessionWidget): Promise<void> {
    await this.client.install(sessionWidget);
    return;
  }

  destroy(): void {
    this.closeEventListeners?.();

    this.handlers.clear();
    this.channel.all.clear();
  }

  private subscribe(handler: SessionStateChangeHandler): () => void {
    handler(this.state);
    this.channel.on(SessionEvent.STATE_CHANGED, handler);

    return () => {
      this.channel.off(SessionEvent.STATE_CHANGED, handler);
    };
  }

  private setupProxyHandlers(initHandlers: SessionStateChangeHandler[] = []): void {
    // TODO: Handle this init case better
    if (initHandlers.length) {
      initHandlers.forEach((value, index) => {
        if (!this.handlers.has(index.toString())) {
          const unsubscribe = this.subscribe(value);
          this.handlers.set(index.toString(), unsubscribe);
        }
      });
    }

    const proxy = new Proxy<SessionStateChangeHandler[]>([], {
      get: (target, prop, receiver) => Reflect.get(target, prop, receiver),
      set: (target, prop, value) => {
        if (!this.handlers.has(prop)) {
          const unsubscribe = this.subscribe(value);
          this.handlers.set(prop, unsubscribe);
        } else {
          const unsubscribe = this.handlers.get(prop);
          this.handlers.delete(prop);
          unsubscribe?.();
        }

        return Reflect.set(target, prop, value);
      },
    });

    Object.setPrototypeOf(initHandlers, proxy);
    return;
  }

  private setupSessionEventListeners(): () => void {
    // Bootstrap process
    const installationFailed = this.onInstallFailed.bind(this);
    const installationStarted = this.onInstallStarted.bind(this);
    const installationFinished = this.onInstallFinished.bind(this);

    // Fetching process
    const fetchingStarted = this.onFetchingStarted.bind(this);
    const fetchingFinished = this.onFetchingFinished.bind(this);

    // Init process
    const initializationStarted = this.onInitializationStarted.bind(this);
    const initializationFinished = this.onInitializationFinished.bind(this);

    this.channel.on(SessionEvent.SESSION_INSTALLATION_FAILED, installationFailed);
    this.channel.on(SessionEvent.SESSION_INSTALLATION_STARTED, installationStarted);
    this.channel.on(SessionEvent.SESSION_INSTALLATION_FINISHED, installationFinished);

    this.channel.on(SessionEvent.SESSION_WIDGET_FETCHING_STARTED, fetchingStarted);
    this.channel.on(SessionEvent.SESSION_WIDGET_FETCHING_FINISHED, fetchingFinished);

    this.channel.on(SessionEvent.SESSION_WIDGET_INITIALIZATION_STARTED, initializationStarted);
    this.channel.on(SessionEvent.SESSION_WIDGET_INITIALIZATION_FINISHED, initializationFinished);

    return (): void => {
      this.channel.off(SessionEvent.SESSION_INSTALLATION_FAILED, installationFailed);
      this.channel.off(SessionEvent.SESSION_INSTALLATION_STARTED, installationStarted);
      this.channel.off(SessionEvent.SESSION_INSTALLATION_FINISHED, installationFinished);

      this.channel.off(SessionEvent.SESSION_WIDGET_FETCHING_STARTED, fetchingStarted);
      this.channel.off(SessionEvent.SESSION_WIDGET_FETCHING_FINISHED, fetchingFinished);

      this.channel.off(SessionEvent.SESSION_WIDGET_INITIALIZATION_STARTED, initializationStarted);
      this.channel.off(SessionEvent.SESSION_WIDGET_INITIALIZATION_FINISHED, initializationFinished);

      return;
    };
  }

  private dispatch(action: SessionAction) {
    this.state = sessionStateReducer(this.state, action);
    this.channel.emit(SessionEvent.STATE_CHANGED, this.state);
  }

  private onInstallStarted(options: SessionOptions): void {
    this.dispatch({ type: SessionActionType.SESSION_INSTALLATION_STARTED, options });
  }

  private onInstallFinished(): void {
    // Not in use at the moment
  }

  private onInstallFailed(error: SessionErrorCode): void {
    this.dispatch({ type: SessionActionType.SESSION_INSTALLATION_FAILED, error });
  }

  private onFetchingStarted(): void {
    this.dispatch({ type: SessionActionType.SESSION_WIDGET_FETCHING_FINISHED });
  }

  private onFetchingFinished(): void {
    this.dispatch({ type: SessionActionType.SESSION_WIDGET_FETCHING_STARTED });
  }

  private onInitializationStarted(): void {
    this.dispatch({ type: SessionActionType.SESSION_WIDGET_INITIALIZATION_STARTED });
  }

  private onInitializationFinished(): void {
    this.dispatch({ type: SessionActionType.SESSION_WIDGET_INITIALIZATION_FINISHED });
  }
}
