import { SessionOptions } from './session-options';
import { SessionWidgetFetchingError, SessionWidgetMissingError, SessionWidgetInitializationError } from './session-errors';

export interface SessionWidgetOptions extends SessionOptions {
  url?: string;
}

export const DEFAULT_SESSION_WIDGET_URL = 'https://gateway.jerkmate.com/wswidget/ws-session-widget.min.js';

export interface SessionWidget {
  readonly options: SessionWidgetOptions;

  fetch(): Promise<void>;
  cleanup(): Promise<void>;
  initialize(): Promise<void>;
}

export class BrowserSessionWidget implements SessionWidget {
  readonly options: SessionWidgetOptions;

  private readonly script: HTMLScriptElement = document.createElement('script');

  constructor(options: SessionWidgetOptions) {
    const { url, ...opts } = options;
    this.options = { url: url || DEFAULT_SESSION_WIDGET_URL, ...opts };
  }

  fetch(): Promise<void> {
    return new Promise((res, rej) => {
      this.script.src = this.options.url as string;
      this.script.type = 'text/javascript';
      this.script.async = true;

      const onSuccess = () => res();
      this.script.addEventListener('load', onSuccess);

      const onError = (err: ErrorEvent) => {
        rej(new SessionWidgetFetchingError(err?.message));
        return;
      };

      this.script.addEventListener('error', onError);

      document.body.appendChild(this.script);
    });
  }

  async cleanup(): Promise<void> {
    this.script.parentElement?.removeChild(this.script);
  }

  initialize(): Promise<void> {
    return new Promise((res, rej) => {
      const { sessionWidget } = window;

      if (typeof sessionWidget?.init !== 'function') {
        rej(new SessionWidgetMissingError());
        return;
      }

      const handleSessionWidgetInit = (isOk: boolean): void => {
        if (!isOk) {
          rej(new SessionWidgetInitializationError());
          return;
        }

        res();
        return;
      };

      sessionWidget?.init(this.options, handleSessionWidgetInit);
    });
  }
}
